import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {useEffect} from 'react';
import Script from 'next/script';
import {
  trackEvent,
  GOOGLE_ONE_TAP_ERROR,
  GOOGLE_ONE_TAP_SUCCESS,
  EMAIL_LOGIN,
  USER_LOGIN,
  CLIENT_LOGIN,
  CLIENT_SIGNUP,
} from '../../lib/analytics';
import {setSessionCookie, deleteCookie} from '../../lib/utils/cookies';
import {getUrlParam, deleteURLParam} from '../../lib/utils/urlParams';
import {isBrowser} from '../../lib/utils/common';

const VALID_SECONDS_TO_SIGNUP = 60;

function isValidSignup(userData) {
  if (!userData.created_at) {
    return false;
  }
  const creationDate = userData.created_at;
  const currentDate = new Date();
  const userCreationDate = new Date(creationDate);
  userCreationDate.setSeconds(userCreationDate.getSeconds() + VALID_SECONDS_TO_SIGNUP);
  if (currentDate.getTime() > userCreationDate.getTime()) {
    return false;
  }
  return true;
}

export default function OneTap({user}) {
  const nonce = v4();
  const source = 'google_one_tap';

  useEffect(() => {
    if (isBrowser()) {
      window.oneTapMomentCallback =
        window.oneTapMomentCallback ||
        function oneTapMomentCallback(notification) {
          if (notification.getSkippedReason() === 'issuing_failed') {
            trackEvent(GOOGLE_ONE_TAP_ERROR, {
              label: source,
            });
          }

          if (notification.getDismissedReason() === 'credential_returned') {
            const {href} = window.location;
            const url = `${href}?google_one_tapFallback=true`;
            setSessionCookie('pageState', url);
          }
        };

      return () => {
        delete window.oneTapMomentCallback;
      };
    }
  }, []);

  useEffect(() => {
    if (isBrowser() && getUrlParam('google_one_tapFallback') && user?.loggedIn === true) {
      deleteURLParam('google_one_tapFallback');
      deleteCookie('pageState');

      if (isValidSignup(user)) {
        trackEvent(CLIENT_SIGNUP, {label: source});
      }
      trackEvent(GOOGLE_ONE_TAP_SUCCESS, {label: source});
      trackEvent(EMAIL_LOGIN, {label: source});
      trackEvent(USER_LOGIN, {label: source});
      trackEvent(CLIENT_LOGIN, {label: source});
    }
  }, [user]);

  if (user?.loggedIn !== false) {
    return null;
  }
  return (
    <>
      <Script src="https://accounts.google.com/gsi/client" strategy="lazyOnload" />
      <div
        id="g_id_onload"
        data-client_id={process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_CLIENT_ID}
        data-login_uri={process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_URI}
        data-nonce={nonce}
        data-context="use"
        data-auto_prompt="true"
        data-itp_support="true"
        data-moment_callback="oneTapMomentCallback"
      />
    </>
  );
}

OneTap.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.oneOf([true, false, 'loading']),
    accountType: PropTypes.string,
    plan: PropTypes.oneOf(['monthly', 'annual', null]),
  }),
};

OneTap.defaultProps = {
  user: {
    loggedIn: false,
    accountType: '',
    plan: null,
  },
};
