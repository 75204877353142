import {
  SEARCH_NO_RESULTS,
  SEARCH_FEW_RESULTS,
  SEARCH_SUCCESS,
  EMAIL_SEARCH_SUCCESS,
  trackEvent,
} from '../../../lib/analytics';
import debounce from '../../../lib/utils/debounce';

const EVENT_DEBOUNCE_TIMER = 2000;

const debouncedTrackResults = debounce(trackResults, EVENT_DEBOUNCE_TIMER);
let currentResults = null;
let query = null;

function reportResults(results) {
  currentResults = results;
  debouncedTrackResults();
}

function reportSubmission() {
  trackResults();
}

function reportQuery(newQuery) {
  query = newQuery;
}

function trackResults() {
  const results = currentResults;
  if (!currentResults) {
    // reported results have already been submitted
    return;
  }
  if (results.query !== query) {
    // query is stale, not reporting
    return;
  }
  const numberOfHits = results.nbHits;
  const eventProps = {searchTerm: results.query, numberOfMatches: numberOfHits};

  if (numberOfHits === 0) {
    return trackEvent(SEARCH_NO_RESULTS, eventProps);
  }
  if (numberOfHits <= 10) {
    return trackEvent(SEARCH_FEW_RESULTS, eventProps);
  }

  trackEvent(SEARCH_SUCCESS, eventProps);
  trackEvent(EMAIL_SEARCH_SUCCESS, eventProps);
  currentResults = null;
}

export {reportResults, reportSubmission, reportQuery};
