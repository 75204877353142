import {useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../data/NavContext';

export default function Banner({children}) {
  const {isBannerVisible} = useContext(NavContext);

  if (isBannerVisible) {
    return children;
  }
  return null;
}

Banner.defaultProps = {
  children: null,
  className: null,
};
Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const BannerPlasmicData = {
  name: 'Nav.Banner',
  props: {
    children: {
      type: 'slot',
    },
  },
  importPath: './components/NavBar/Banner',
  isDefaultExport: true,
};
