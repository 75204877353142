import PropTypes from 'prop-types';
// doesn't make sense to export this constant as default, therefore:
// eslint-disable-next-line import/prefer-default-export
export const breakpoints = {
  sm: 600,
  md: 900,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1900,
  '4xl': 2048,
};

export const pdpBreakpoints = {
  sm: 600,
  md: 900,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1900,
};

export const defaultGridColumns = {
  sm: 2,
  md: 3,
  lg: 3,
  xl: 4,
  '2xl': 4,
  '3xl': 4,
};

export const defaultGridColumnsPropTypes = PropTypes.shape({
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  '2xl': PropTypes.number,
  '3xl': PropTypes.number,
});

export const BUNDLE_TYPE_ID = 16;

export const productTypes = {
  13: 'image',
  15: 'video',
  16: 'bundle',
  18: 'expressVideo',
};
