import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Trans} from '@lingui/macro';
import BaseButton from '../../../UI/Molecules/BaseButton';
import {UserContext} from '../../../../context/UserContext';

export default function LoginSidePanel({closePanel}) {
  const {ssoModalOpener} = useContext(UserContext);

  const handleBtns = type => {
    closePanel();
    ssoModalOpener(type);
  };

  return (
    <div className="flex flex-col flex-nowrap items-start border-t border-gray-100 px-4 pt-6">
      <BaseButton className="mb-2 h-9 w-full" onClick={() => handleBtns(true)}>
        <Trans>Free Account</Trans>
      </BaseButton>
      <BaseButton
        className="h-9 w-full p-0"
        variant="primaryOutlined"
        onClick={() => handleBtns(false)}
      >
        <Trans>Login</Trans>
      </BaseButton>
    </div>
  );
}
LoginSidePanel.defaultProps = {
  closePanel: () => null,
};
LoginSidePanel.propTypes = {
  closePanel: PropTypes.func,
};
