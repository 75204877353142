import {useContext} from 'react';
import Headerstrip from '../../UI/Molecules/Headerstrip';
import {UserContext} from '../../../context/UserContext';

const pastDueCopy =
  'Your subscription payment failed and is now overdue. To keep your subscription active, please verify that your payment details are correct.';

function redirectToMyAccount() {
  window.location = '/account';
}

export default function PastDueNotification() {
  const {currentUser} = useContext(UserContext);
  if (currentUser?.is_past_due === true) {
    return (
      <Headerstrip
        type="danger"
        message={pastDueCopy}
        button="Update payment details"
        onClick={redirectToMyAccount}
      />
    );
  }
  return null;
}
