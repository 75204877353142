import React, {useContext} from 'react';
import Image from 'next/image';
import CategoryMenu from './CategoryMenu';
import AccountMenu from './AccountMenu';
import {UserContext} from '../../../context/UserContext';
import PricingButton from './PricingButton';
import Logo from './Logo';

export default function DesktopMenuBar() {
  const {currentUser} = useContext(UserContext);
  return (
    <>
      <div className="relative z-50 mx-auto mt-4 hidden w-full items-center justify-between px-6 pb-1 lg:flex">
        <div className="flex h-11 w-full items-center text-white">
          <Logo className="flex items-center">
            <Image
              src="/static/placeit-and-envato.svg"
              alt="logo"
              height="32px"
              width="64px"
              layout="fixed"
              priority
            />
          </Logo>
          <CategoryMenu className="z-50 flex items-center justify-start" />
        </div>
        <div className="flex items-center">
          <PricingButton variant="greenPricing" size="sm" />
          <AccountMenu />
        </div>
      </div>
      <div className="w-22 absolute right-0 top-0 z-50 flex w-20 items-center rounded-bl-lg bg-white">
        {currentUser?.is_admin && (
          <a className="h-4 w-full text-center text-xs" href="/admin/stages">
            Admin
          </a>
        )}
      </div>
    </>
  );
}
