import React, {useContext} from 'react';
import {UserContext} from '../../../context/UserContext';
import {PRICEBUTTON_CLICK, trackEvent} from '../../../lib/analytics';
import BaseButton from '../../UI/Molecules/BaseButton';

export default function PricingButton(props) {
  const {currentUser} = useContext(UserContext);

  // Return null if user has an annual subscription plan,
  if (currentUser?.plan === 'annual') {
    return null;
  }

  const handleClick = () => {
    trackEvent(PRICEBUTTON_CLICK, {button_clicked: 'pricing-button'});
  };
  const redirection = currentUser?.plan === 'monthly' ? '/account?go_annual=true' : '/pricing';
  const label = currentUser?.plan === 'monthly' ? 'Go Annual!' : 'Go Unlimited!';

  // Return a Go Unlimited button
  return (
    <>
      {currentUser?.plan !== 'monthly' && (
        <a
          href="/pricing"
          title="Pricing"
          className="relative mr-4 flex h-full items-center whitespace-nowrap text-xs text-white transition-all hover:text-gray-400 lg:mr-8 lg:text-base"
        >
          <span className="text-xs lg:text-base">Pricing</span>
        </a>
      )}
      <a onClick={handleClick} href={redirection} tabIndex={-1} className="relative">
        <BaseButton {...props}>
          <p className="flex h-full w-full items-center justify-center text-xs font-normal lg:text-sm">
            {label}
          </p>
        </BaseButton>
      </a>
    </>
  );
}

export const PricingButtonPlasmicData = {
  name: 'Nav.PricingButton',
  props: {
    variant: {
      displayName: 'Variant',
      type: 'choice',
      options: ['primaryRegular', 'capsule', 'tertiary', 'greenPricing'],
      defaultValue: 'greenPricing',
    },
  },
  defaultStyles: {height: '2.25rem'},
  importPath: './components/NavBar/MenuBar/PricingButton',
  isDefaultExport: true,
};
