import React, {useContext, useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../../data/NavContext';
import CategoryItem from './CategoryItem';
import ThreeDotMenu from './ThreeDotMenu';
import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';

export default function CategoryMenu({className}) {
  const {menuList} = useContext(NavContext);
  const [menuItemsToShow, setMenuItemsToShow] = useState(
    typeof window !== 'undefined' ? 2 : menuList.length
  );
  const hiddenRef = useRef(null);
  const menuRef = useRef(null);
  const windowDimensions = useWindowDimensions();
  // used for the 3-Dot collapsable category menu
  const [divLength, setDivLength] = useState(0);

  // calculates the space between the Category Menu and User Menu
  useEffect(() => {
    setDivLength(hiddenRef.current.offsetWidth);
  }, [windowDimensions.width]);

  // calculates the # of items to showin the category menu
  useEffect(() => {
    if (windowDimensions.width > 1359) {
      setMenuItemsToShow(menuList.length);
    } else if (divLength > 280 && menuItemsToShow < menuList.length) {
      setMenuItemsToShow(menuItemsToShow + 1);
    } else if (divLength < 150 && menuItemsToShow > 2) {
      setMenuItemsToShow(menuItemsToShow - 1);
    }

    setDivLength(hiddenRef.current.offsetWidth);
  }, [divLength]);

  return (
    <>
      <div ref={menuRef} className={className} data-qa="menu">
        {menuList.map(({id, ...otherProps}) => {
          // Exclude the first item, the all categories obj used only for filter in searchbar
          if (id !== 0 && id <= menuItemsToShow) {
            return <CategoryItem key={id} {...otherProps} />;
          }
          return null;
        })}
        {menuItemsToShow < menuList.length && (
          <ThreeDotMenu itemsShown={menuItemsToShow} menuList={menuList} />
        )}
      </div>
      <div ref={hiddenRef} className="min-width-[150px] w-full">
        &nbsp;
      </div>
    </>
  );
}

CategoryMenu.defaultProps = {
  screenwidth: 1025,
  className: '',
};

CategoryMenu.propTypes = {
  screenwidth: PropTypes.number,
  className: PropTypes.string,
};
