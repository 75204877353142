import React, {useEffect, useState, useRef, useCallback} from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import wait from '../../../../lib/utils/wait';
import CancelButton from './CancelButton';
import FadeBackground from './FadeBackground';
import Logo from '../../MenuBar/Logo';

// CONSTANTS
const PANEL = {
  left: {
    close: '-left-full',
    open: 'animate-fadeInRight left-0',
    button: 'm-4 self-start',
  },
  right: {
    close: '-right-full',
    open: 'animate-fadeInLeft right-0',
    button: 'm-4 self-end',
  },
  background: {
    open: 'animate-fadeIn',
    close: 'animate-fadeOut',
  },
};

// COMPONENT
export default function Panel({children, side, isActive, setIsActive, showLogo}) {
  const [panelAnimation, setPanelAnimation] = useState(PANEL[side].close);
  const [backgroundAnimation, setBackgroundAnimation] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const mountedRef = useRef(true);

  // Side effect to stop page to be scrollable when panel is visible
  useEffect(() => {
    if (isActive) {
      document.body.classList.add('overflow-hidden', 'fixed');
    } else {
      document.body.classList.remove('overflow-hidden', 'fixed');
    }
  }, [isActive]);

  // Side effect to manage animation of the panel
  useEffect(() => {
    asyncAnimate();
    return () => {
      setIsOpen(false);
      mountedRef.current = false;
    };
  }, [isActive]);

  // Async animation callback occurs when the panel is about to be closed.
  const asyncAnimate = useCallback(async () => {
    if (isActive) {
      setBackgroundAnimation(PANEL.background.open);
      setPanelAnimation(PANEL[side].open);
      setIsOpen(true);
    } else {
      setBackgroundAnimation(PANEL.background.close);
      setPanelAnimation(PANEL[side].close);
      // wait 300 ms to animate panel and bg before removing them out of the dom
      await wait(300, () => {
        if (mountedRef.current) {
          setIsOpen(false);
        }
      });
    }
  });

  // Handle close of the panel
  const handleClose = () => {
    setIsActive(false);
  };

  if (isOpen) {
    return (
      <>
        <div
          className={`fixed top-0 z-40 flex h-screen w-[80vw] flex-col bg-white transition-all duration-700 ${panelAnimation}`}
        >
          <div className={`flex ${showLogo === false ? 'justify-between' : 'justify-end'}  pl-7`}>
            {showLogo === false && (
              <Logo className="flex items-center">
                <Image
                  src="/static/placeit-and-envato-black.svg"
                  alt="logo"
                  height="32px"
                  width="64px"
                  priority
                />
              </Logo>
            )}
            <CancelButton onClick={handleClose} className={PANEL.right.button} color="black" />
          </div>
          {children}
        </div>
        <FadeBackground
          isActive={isOpen}
          onClick={handleClose}
          animation={backgroundAnimation}
          zIndex="z-30"
        />
      </>
    );
  }

  return null;
}

Panel.defaultProps = {
  children: null,
  showLogo: false,
};
Panel.propTypes = {
  children: PropTypes.node,
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  showLogo: PropTypes.bool,
};
