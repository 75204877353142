import React from 'react';
import PropTypes from 'prop-types';
import IconCross from '../../../../public/static/icon-cross.svg';

const BORDER = {
  black: 'border-black',
  white: 'border-white',
};
const ICON = {
  black: '',
  white: 'invert',
};

export default function CancelButton({onClick, className, color}) {
  const borderColor = BORDER[color];
  const iconColor = ICON[color];

  return (
    <button
      type="button"
      className={`flex h-8 w-8 items-center justify-center ${borderColor} ${className}`}
      onClick={onClick}
      aria-label="close"
      data-qa="search-cancel"
    >
      <IconCross className={`${iconColor} h-3.5 w-3.5`} />
    </button>
  );
}

CancelButton.defaultProps = {
  className: '',
  color: 'black',
};
CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['black', 'white']),
};
