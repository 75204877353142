import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../UI/Molecules/Button';
import {IcSearch01 as SearchIcon} from '../../../UI/Atoms/Icons';

export default function SubmitButton({className}) {
  return (
    <Button type="submit" className={className} variant="midnightVerticalB" size="none">
      <SearchIcon className="h-5 w-5 text-white" />
    </Button>
  );
}

SubmitButton.defaultProps = {
  className: '',
};
SubmitButton.propTypes = {
  className: PropTypes.string,
};
