import PropTypes from 'prop-types';
import {trackEvent} from '../lib/analytics';
import {getCookie} from '../lib/utils/cookies';

function ClickEventReporter({children, eventName, customProperties, includeTrackerId}) {
  const handleClick = () => {
    if (includeTrackerId) {
      customProperties = {
        ...customProperties,
        session: getCookie('pl_tracker_id_v2'),
      };
    }
    trackEvent(eventName, customProperties);
  };
  return (
    <div role="button" tabIndex={0} onKeyDown={handleClick} onClick={handleClick}>
      {children}
    </div>
  );
}

export default ClickEventReporter;

ClickEventReporter.propTypes = {
  children: PropTypes.node.isRequired,
  eventName: PropTypes.string.isRequired,
  customProperties: PropTypes.shape({}),
  includeTrackerId: PropTypes.bool,
};

ClickEventReporter.defaultProps = {
  customProperties: {},
  includeTrackerId: false,
};

export const PlasmicData = {
  name: 'ClickEventReporter',
  props: {
    eventName: {
      type: 'string',
      defaultValue: 'myTestEvent',
    },
    customProperties: 'object',
    children: 'slot',
    includeTrackerId: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Include tracker id?',
      description:
        'If the value is true, the tracker id will be added in the event properties with the name session',
    },
  },
  importPath: './components/ClickEventReporter',
  isDefaultExport: true,
};
