import React from 'react';
import PropTypes from 'prop-types';
import {EMAIL_MOBILEMENU_CLICKED, MOBILEMENU_CLICKED, trackEvent} from '../../../../lib/analytics';

export default function CategorySubMenuDropdown({subCategories, animateDropdown}) {
  const handleClick = path => {
    trackEvent(MOBILEMENU_CLICKED, {label: path});
    trackEvent(EMAIL_MOBILEMENU_CLICKED, {label: path});
  };

  return (
    <ul className={`${animateDropdown} ml-9 list-none border-l border-gray-100 p-0`}>
      {subCategories.map(({label, id, path}) => (
        <li key={id} className=" flex py-3 text-sm">
          <a
            href={path}
            className="w-full px-4"
            aria-label={`sub-item-link-${label}`}
            onClick={() => handleClick(path)}
          >
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
}

CategorySubMenuDropdown.propTypes = {
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  animateDropdown: PropTypes.string,
};

CategorySubMenuDropdown.defaultProps = {
  animateDropdown: '',
};
