import React from 'react';
import MobileMenuBar from '../Mobile/MobileMenuBar';
import DesktopMenuBar from './DesktopMenuBar';

export default function MenuBar() {
  return (
    <>
      <MobileMenuBar />
      <DesktopMenuBar />
    </>
  );
}

export const MenuBarPlasmicData = {
  name: 'Nav.MenuBar',
  props: {},
  importPath: './components/NavBar/MenuBar',
  isDefaultExport: true,
};
