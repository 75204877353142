// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export const ScreenContext = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export const useScreenVariants = createUseScreenVariants(true, {
  mobileM: "(min-width:600px)",
  mobileL: "(min-width:900px)",
  desktopS: "(min-width:1024px)",
  desktopM: "(min-width:1280px)",
  desktopL: "(min-width:1536px)",
  desktopXl: "(min-width:1900px)",
});

export default ScreenContext;
/* prettier-ignore-end */
