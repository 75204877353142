import React from 'react';
import CategoryMenuPanel from './CategoryMenuPanel';
import Burger from './CategoryMenuPanel/Burger';
import MobileUserMenu from './MobileUserMenu';
import {IcMenuHamburger as BurgerIcon} from '../../UI/Atoms/Icons';

export default function MobileMenuBar() {
  return (
    <div className="relative mx-auto mb-1 mt-2 flex h-11 w-full items-center justify-between px-4 lg:hidden">
      <div className="flex items-center">
        <CategoryMenuPanel>
          <Burger className="z-10 mr-2.5 items-center justify-center rounded-full fill-white">
            <BurgerIcon className="h-6 w-6 fill-white" />
          </Burger>
        </CategoryMenuPanel>
      </div>
      <MobileUserMenu />
    </div>
  );
}
