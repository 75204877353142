// Generic debounce function
export default function debounce(fn, time = 200) {
  let timerId;

  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise(resolve => {
      timerId = setTimeout(() => {
        return resolve(fn(...args));
      }, time);
    });
  };
}
