import React from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';
import Filter from '../../SearchBar/Filter';
import SubmitButton from '../../SearchBar/Form/SubmitButton';
import {breakpoints} from '../../../../lib/constants';

export default function MobileActiveSearch({setActivate}) {
  const {width} = useWindowDimensions();
  const isMobile = width < breakpoints.lg;

  if (isMobile) {
    return (
      <>
        <div className="absolute -top-[60px] left-0 right-0 z-20 flex h-[54px] w-full animate-dropDown items-center justify-between bg-black">
          <Filter />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
          <span role="button" onClick={() => setActivate(false)} className="text-white">
            Cancel
          </span>
        </div>
        <SubmitButton className="z-10 ml-1.5 h-8 w-9 animate-popIn duration-1000" />
      </>
    );
  }
  return null;
}

MobileActiveSearch.propTypes = {
  setActivate: PropTypes.func.isRequired,
};
