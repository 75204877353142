import React from 'react';
import PropTypes from 'prop-types';

export default function FilterButton({filter, isOpen, onClick}) {
  // CONDITIONAL STYLES
  const arrowStyles = isOpen ? 'rotate-180' : 'rotate-0';
  const buttonStyles = isOpen ? 'lg:bg-gray-100' : 'lg:bg-white';

  return (
    <button
      type="button"
      className={`${buttonStyles} flex h-7 min-w-[0.7rem] items-center justify-around whitespace-nowrap rounded-3xl bg-white/25 px-4 py-0.5 text-center text-base capitalize text-white outline-none lg:h-full lg:w-[10rem] lg:min-w-min lg:rounded-none lg:rounded-bl-md  lg:rounded-tl-md lg:px-6 lg:text-[0.9375rem] lg:text-black`}
      onClick={onClick}
      aria-label={filter}
    >
      {/* TEXT */}
      <span>{filter}</span>

      {/* ARROW */}
      <span
        className={`pointer-events-none ml-2.5 h-1.5 w-2 bg-[url('/static/dropdown-arrow.svg')] bg-contain bg-center bg-no-repeat invert transition-all lg:h-full lg:w-2 lg:invert-0 ${arrowStyles}`}
      />
    </button>
  );
}

FilterButton.propTypes = {
  filter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
