import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from '../../../../../context/UserContext';
import NavContext from '../../../data/NavContext';

import UserOptionsHeader from './UserOptionsHeader';
import UserOption from './UserOption';
import ssoLogout from '../../../../../lib/ssoLogout';

export default function UserOptions({className, screen, closePanel}) {
  const {logoutUser, currentUser} = useContext(UserContext);
  const {userOptions} = useContext(NavContext);

  const handleClick = () => {
    if (screen === 'isMobile') {
      closePanel();
    }
    return null;
  };

  return (
    <div className={className}>
      <UserOptionsHeader />
      {userOptions.map(({id, label, icon, href, ...props}) => {
        if (props[screen]) {
          return (
            <UserOption name={label} href={href} icon={icon} key={id} onClick={handleClick}>
              {label}
            </UserOption>
          );
        }
        return null;
      })}

      <UserOption
        isLogoutItem
        onClick={() => {
          const requiresSsoLogout = logoutUser();
          if (requiresSsoLogout) {
            ssoLogout(currentUser);
          }
          handleClick();
        }}
      />
    </div>
  );
}
UserOptions.defaultProps = {
  className: '',
  closePanel: () => null,
  screen: 'isDesktop',
};
UserOptions.propTypes = {
  className: PropTypes.string,
  closePanel: PropTypes.func,
  screen: PropTypes.oneOf(['isDesktop', 'isMobile']),
};
