import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IcThreeDots} from '../../../../UI/Atoms/Icons';
import ThreeDotDropDown from './ThreeDotDropDown';

export default function ThreeDotMenu({itemsShown, menuList}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="group relative z-50 ml-4 h-full cursor-pointer"
      onMouseLeave={() => setIsOpen(false)}
    >
      <button className="flex items-center" type="button" onMouseEnter={() => setIsOpen(true)}>
        <IcThreeDots className="h-8 w-8 fill-gray-900 hover:fill-gray-800" />
      </button>
      {isOpen && <ThreeDotDropDown menuItems={menuList} itemsShown={itemsShown} />}
    </div>
  );
}

ThreeDotMenu.propTypes = {
  itemsShown: PropTypes.number,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      subCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
};

ThreeDotMenu.defaultProps = {
  itemsShown: 0,
  menuList: [],
};
