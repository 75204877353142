import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../../data/NavContext';

export default function Burger({children, className}) {
  const {setIsMenuPanelOpen} = useContext(NavContext);

  // RENDER
  return (
    <button
      type="button"
      className={className}
      onClick={() => setIsMenuPanelOpen(true)}
      aria-label="burger"
    >
      {children}
    </button>
  );
}

Burger.defaultProps = {
  children: null,
  className: '',
};
Burger.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
