import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {Trans} from '@lingui/macro';
import Text from '../../../UI/Atoms/Typography/Text';
import ResetButton from './ResetButton';

const AutocompleteContainer = dynamic(() => import('../Autocomplete/AutocompleteContainer'));

export default function Input({
  onChange,
  value,
  setInputSearch,
  placeholder,
  onClick,
  onSubmit,
  resultsNumber,
  isSearchingState,
}) {
  const [inputProps, setInputProps] = useState({onChange, onClick, value, placeholder});
  const [canLoad, setCanLoad] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [totalResults, setTotalResults] = useState(null); // totalResults temporarily not used because #8558
  const [canReset, setCanReset] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({start: 0, end: 0});

  useEffect(() => {
    setInputProps(state => ({...state, value, placeholder}));
    setCanReset(value !== '');
  }, [value, placeholder]);

  useEffect(() => {
    setTotalResults(resultsNumber);
  }, [resultsNumber]);

  const inputRef = useRef(null);

  const focusHandler = () => {
    setCanLoad(true);
  };

  const resetClickHandler = () => {
    setInputSearch('');
    setCanReset(false);
    setCursorPosition({start: 0, end: 0});
    setInputProps(state => ({...state, value, placeholder}));
  };

  const showNoResultsText = false; // totalResults === 0 && canReset; Removed because #8558
  const showResetButton = canReset;

  const updateCursorOnInput = (start, end) => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(start, end);
    }
  };

  useEffect(() => {
    requestAnimationFrame(updateCursorOnInput.bind(this, cursorPosition.start, cursorPosition.end));
  }, [cursorPosition]);

  const onChangeHandler = criteria => {
    onChange(criteria);
    setCursorPosition({start: inputRef.current.selectionStart, end: inputRef.current.selectionEnd});
  };

  const onClickHandler = e => {
    setInputSearch(e.target.value);
    onClick(e);
    setCursorPosition({start: e.target.selectionStart, end: e.target.selectionEnd});
  };

  return (
    <>
      <input
        className="peer relative h-8 w-full flex-auto grow rounded-md border-0 pl-9 pr-4 text-inherit placeholder-gray-600 outline-none transition-all focus:px-4 focus:py-2.5 lg:z-0 lg:h-full lg:rounded-none lg:border-l lg:border-gray-300 lg:px-4 lg:py-2.5"
        onFocus={focusHandler}
        aria-label="search-input"
        data-qa="search-input"
        ref={inputRef}
        value={value}
        {...inputProps}
      />
      {showNoResultsText && (
        <Text
          className="absolute right-[82px] top-[11px] font-source-sans-pro font-normal leading-tight text-red-600 "
          size="md"
        >
          <Trans>(No results)</Trans>
        </Text>
      )}
      {showResetButton && (
        <ResetButton
          className="absolute right-[11px] top-[7px] z-0 box-border h-5 w-5 rounded-full bg-gray-200 p-1 transition-all hover:bg-gray-300 peer-focus:right-[50px] lg:right-[53px] lg:top-2.5 lg:peer-focus:right-[53px]"
          resetClickHandler={resetClickHandler}
        />
      )}
      {canLoad && isSearchingState && (
        <AutocompleteContainer
          submitHandler={onSubmit}
          setTotalResults={setTotalResults}
          {...{
            inputRef,
            setInputProps,
            value,
            placeholder,
          }}
          onClick={onClickHandler}
          onChange={onChangeHandler}
        />
      )}
    </>
  );
}

Input.defaultProps = {
  value: '',
  setInputSearch: () => null,
  placeholder: '',
  onClick: () => null,
  resultsNumber: null,
  isSearchingState: false,
};
Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string,
  setInputSearch: PropTypes.func,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  resultsNumber: PropTypes.number,
  isSearchingState: PropTypes.bool,
};
