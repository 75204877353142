import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import NavContext from '../../data/NavContext';
import Panel from '../Panel';
import AccountMenu from './AccountMenu';
import {UserContext} from '../../../../context/UserContext';
import LoginSidePanel from './LoginSidePanel';

export default function AccountPanel({children}) {
  const {isAccountPanelOpen, setIsAccountPanelOpen, mockLogin} = useContext(NavContext);
  const {currentUser} = useContext(UserContext);
  const router = useRouter();

  return (
    <>
      <div
        className={`relative flex cursor-pointer flex-col items-center justify-center whitespace-nowrap text-sm text-gray-400 hover:text-white ${
          router?.route === '/account'
            ? 'border-b-4 border-primary-400 text-white'
            : 'text-gray-400'
        }`}
      >
        {children} {/* space for the navbar Avatar button */}
      </div>
      <Panel
        side="right"
        isActive={isAccountPanelOpen}
        setIsActive={setIsAccountPanelOpen}
        showLogo={currentUser?.loggedIn === !mockLogin}
      >
        {currentUser?.loggedIn === !mockLogin ? (
          <AccountMenu closePanel={() => setIsAccountPanelOpen(false)} />
        ) : (
          <LoginSidePanel closePanel={() => setIsAccountPanelOpen(false)} />
        )}
      </Panel>
    </>
  );
}
AccountPanel.defaultProps = {
  children: null,
};
AccountPanel.propTypes = {
  children: PropTypes.node,
};
