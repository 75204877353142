import React, {useContext} from 'react';
import Image from 'next/image';
import UserMenuItem from '../MenuBar/AccountMenu/UserMenu/UserMenuItem';
import {UserContext} from '../../../context/UserContext';
import NavContext from '../data/NavContext';
import {IcForYou as ForYouIcon, IcMyPlaceit as MyPlaceit} from '../../UI/Atoms/Icons';
import AccountPanel from './AccountPanel';
import Avatar from './AccountPanel/Avatar';
import useWindowDimensions from '../../../hooks/wizard/useWindowDimensions';
import PricingButton from '../MenuBar/PricingButton';
import Logo from '../MenuBar/Logo';

export default function MobileUserMenu() {
  const {currentUser} = useContext(UserContext);
  const {mockLogin} = useContext(NavContext);
  const windowDimensions = useWindowDimensions();

  if (currentUser?.loggedIn === !mockLogin) {
    return (
      <>
        <div className="relative mr-6 flex w-full items-center justify-center sm:absolute sm:left-0">
          <UserMenuItem label="for you" href="/for-you" className="mr-2">
            <ForYouIcon className="h-5 w-5" />
          </UserMenuItem>
          {windowDimensions.width > 599 && (
            <UserMenuItem label="my placeit" href="/my_placeit" className="mr-2">
              <MyPlaceit className="h-5 w-5" />
            </UserMenuItem>
          )}
          <AccountPanel>
            <Avatar className="flex h-5 w-5 items-center justify-center" />
          </AccountPanel>
        </div>
        <div className="flex items-center">
          <PricingButton variant="greenPricing" size="sm" className="w-[96px]" />
        </div>
      </>
    );
  }

  return <VisitorMobileUserMenu />;
}

function VisitorMobileUserMenu() {
  return (
    <>
      <div className="flex w-full items-center sm:absolute sm:left-0 sm:justify-center">
        <Logo className="flex items-center">
          <Image
            src="/static/placeit-and-envato.svg"
            alt="logo"
            height="32px"
            width="64px"
            priority
          />
        </Logo>
      </div>
      <div className="flex items-center justify-end">
        <PricingButton variant="greenPricing" size="sm" className="w-[96px]" />
        <AccountPanel>
          <Avatar className="ml-4 flex h-6 w-6 items-center justify-center text-white" />
        </AccountPanel>
      </div>
    </>
  );
}
