import React, {useContext} from 'react';
import {UserContext} from '../../../../../context/UserContext';
import UserAccountType from './UserAccountType';

export default function UserOptionsHeader() {
  const {currentUser} = useContext(UserContext);
  return (
    <div className="flex w-full flex-col border-b border-cold-gray-200 px-6 pb-4 lg:py-4">
      <UserAccountType accountType={currentUser?.accountType} />
      <span className="truncate text-base lowercase text-gray-800">
        {currentUser.username || 'username'}
      </span>
      <span className="text-xs text-gray-400">Envato Username</span>
    </div>
  );
}
