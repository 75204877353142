/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from '../../../../context/UserContext';
import NavContext from '../../data/NavContext';
import {IcUser01 as IconUser, IcUserLogin as IconVisitor} from '../../../UI/Atoms/Icons';

export default function Avatar({className}) {
  const {currentUser} = useContext(UserContext);
  const {setIsAccountPanelOpen, mockLogin} = useContext(NavContext);

  if (currentUser?.loggedIn === !mockLogin) {
    return (
      <>
        <button
          type="button"
          className={className}
          onClick={() => setIsAccountPanelOpen(true)}
          aria-label="avatar"
        >
          <IconUser className="h-5 w-5" fill="transparent" stroke="white" whites="white" />
        </button>
        <div
          className="text-xs capitalize lg:text-base"
          onClick={() => setIsAccountPanelOpen(true)}
          aria-label="avatar"
        >
          my account
        </div>
      </>
    );
  }

  return (
    <button
      type="button"
      className={className}
      onClick={() => setIsAccountPanelOpen(true)}
      aria-label="avatar"
    >
      <IconVisitor className="h-5 w-5" fill="transparent" stroke="white" whites="white" />
    </button>
  );
}
Avatar.defaultProps = {
  className: '',
};
Avatar.propTypes = {
  className: PropTypes.string,
};
