import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';
import SubMenuDropdown from './CategorySubMenuDropdown';
import {IcViewAll as ViewAll} from '../../../UI/Atoms/Icons';

export default function CategorySubMenu({option, animateCategory, animateSubMenu, reset}) {
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [height, setHeight] = useState(0);
  const [dropdownAnimation, setDropdownAnimation] = useState('');
  const screen = useWindowDimensions();

  const animateSubMenuDropdown = {
    down: 'animate-menuDropDown',
    up: 'animate-menuPullUp',
  };

  // HANDLERS
  const handleExit = () => {
    reset();
  };

  const handleSubcategoryClick = label => {
    if (selectedSubcategory === label) {
      setDropdownAnimation(animateSubMenuDropdown.up);

      // gives time for the animation to run before umount
      setTimeout(() => setSelectedSubcategory(''), 50);
    } else {
      setSelectedSubcategory(label);
    }
  };

  // set the height of the submenu
  useEffect(() => {
    setHeight(screen.height - 56);
  }, [screen]);

  // Resets the animation for submenu dropdowns
  useEffect(() => {
    if (selectedSubcategory) {
      setDropdownAnimation(animateSubMenuDropdown.down);
    } else {
      setDropdownAnimation(animateSubMenuDropdown.up);
    }
  }, [selectedSubcategory]);

  return (
    <div
      className="absolute left-0 top-0 mt-[65px] flex w-full flex-col flex-nowrap border-t border-gray-100 bg-white text-gray-800"
      style={{height}}
    >
      <div className={`flex items-center bg-gray-100 ${animateCategory}`}>
        {/* GO BACK BUTTON */}
        <button
          type="button"
          onClick={handleExit}
          className="flex items-center bg-gray-100 text-lg font-semibold capitalize"
        >
          <span className="my-5 ml-5 inline-block h-3 w-2 rotate-180 bg-[url('/static/right-pointer.svg')] bg-contain bg-center bg-no-repeat invert saturate-0 sepia-0" />
          <span className="ml-2 pr-2">{option.label}</span>
        </button>
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
          <a href={option.path}>
            <ViewAll className="h-6 w-6" />
          </a>
        </div>
      </div>

      <div className="overflow-scroll">
        {/* SUBCATEGORIES LIST  */}
        {option.categories.map(({id, label, subCategories}) => (
          <div key={id}>
            {/* SUBCATEGORY ITEM BLOCK */}
            <button
              className={`flex w-full ${animateSubMenu} cursor-pointer items-center justify-between whitespace-nowrap py-2.5 pl-9 pr-7 text-base capitalize`}
              type="button"
              onClick={() => handleSubcategoryClick(label)}
              aria-label={`sub-item-${label}`}
            >
              {/* TEXT */}
              <span>{label}</span>
              {/* ARROW  */}
              <span
                className={`h-2 w-3 bg-[url('/static/dropdown-arrow.svg')] bg-contain bg-center bg-no-repeat transition-all ${
                  selectedSubcategory === label && 'rotate-180'
                }`}
              />
            </button>

            {/* LAST LIST OF ITEMS  */}
            {selectedSubcategory === label && (
              <SubMenuDropdown {...{subCategories}} animateDropdown={dropdownAnimation} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

CategorySubMenu.propTypes = {
  reset: PropTypes.func.isRequired,
  option: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        subCategories: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  animateCategory: PropTypes.string,
  animateSubMenu: PropTypes.string,
};

CategorySubMenu.defaultProps = {
  animateCategory: '',
  animateSubMenu: '',
};
