import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import UserOptions from '../../MenuBar/AccountMenu/UserOptions';
import {UserContext} from '../../../../context/UserContext';

export default function AccountMenu({closePanel}) {
  const {currentUser} = useContext(UserContext);
  return (
    <div className="flex flex-col flex-nowrap items-start">
      <UserOptions screen="isMobile" className="w-full" closePanel={closePanel} />
      <div className="mt-16 flex w-full py-4 pl-6 text-lg font-semibold text-gray-800 hover:bg-gray-100">
        {currentUser.is_admin && (
          <a className="h-full w-full" href="/admin/stages">
            Admin
          </a>
        )}
      </div>
    </div>
  );
}
AccountMenu.defaultProps = {
  closePanel: () => null,
};
AccountMenu.propTypes = {
  closePanel: PropTypes.func,
};
