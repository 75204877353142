export function getUrlParam(nameParam) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(nameParam);
}

export function deleteURLParam(nameParam) {
  const url = window.location.href;
  if (!nameParam || url.indexOf(nameParam) < 0) {
    return;
  }

  const array = url.split('?');
  const params = array[1].split('&');
  const filteredParams = params.filter(value => value.indexOf(nameParam) < 0);
  const filteredSearch = filteredParams.join('&');
  let newUrl = array[0];
  if (filteredSearch.length > 0) {
    newUrl += `?${filteredSearch}`;
  }
  window.history.replaceState(null, '', newUrl);
}
