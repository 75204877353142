import PropTypes from 'prop-types';
import {useRouter} from 'next/router';

export default function UserMenuItem({children, label, href, className}) {
  const router = useRouter();
  return (
    <a
      href={href}
      title={label}
      className={`relative flex cursor-pointer flex-col items-center justify-center whitespace-nowrap 
       hover:text-white ${router?.route === href ? 'text-white' : 'text-gray-400'} ${className}`}
    >
      <div className="relative flex h-5 w-5 items-center justify-center">{children}</div>
      <div className="text-xs capitalize lg:text-base">{label}</div>
      {router?.route === href && <div className="h-[3px] w-full rounded-full bg-primary-400" />}
    </a>
  );
}

UserMenuItem.defaultProps = {
  children: null,
  label: '',
  href: '',
  className: '',
};
UserMenuItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};
