import React, {useState} from 'react';
import PropTypes from 'prop-types';
import wait from '../../../../lib/utils/wait';

// dropdown animation.
const ANIMATION = {
  INIT: 'opacity-0 -translate-y-14 pointer-events-none',
  FINAL: 'opacity-100 translate-y-0',
};

export default function AccountDropdown({children, Component, className}) {
  const [isOpen, setIsOpen] = useState(false);
  const [animation, setAnimation] = useState(ANIMATION.INIT);

  const handleMouseLeave = async () => {
    // set a close animation for the dropdown box
    setAnimation(ANIMATION.INIT);
    // wait 300 ms to animate the closure of the dropdown
    await wait(300, () => setIsOpen(false));
  };

  const handleMouseEnter = () => {
    setAnimation(ANIMATION.FINAL);
    setIsOpen(true);
  };

  return (
    <div
      className="group relative flex h-full min-w-[16px] justify-center last:pr-0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        type="button"
        className="flex h-full items-center text-sm transition-all group-hover:text-gray-400"
      >
        {children}
      </button>
      {isOpen && (
        <div
          className={`${animation} ${className} z-10 animate-dropDown overflow-hidden rounded-lg transition-all duration-300`}
        >
          <Component />
        </div>
      )}
    </div>
  );
}

AccountDropdown.defaultProps = {
  className: null,
};
AccountDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  Component: PropTypes.func.isRequired,
  className: PropTypes.string,
};
