import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../../data/NavContext';

import Panel from '../Panel';
import CategoryMenu from './CategoryMenu';

export default function CategoryMenuPanel({children}) {
  const {isMenuPanelOpen, setIsMenuPanelOpen} = useContext(NavContext);

  return (
    <>
      {children} {/* here only fits the burger button */}
      <Panel side="left" isActive={isMenuPanelOpen} setIsActive={setIsMenuPanelOpen}>
        <CategoryMenu />
      </Panel>
    </>
  );
}
CategoryMenuPanel.defaultProps = {
  children: null,
};
CategoryMenuPanel.propTypes = {
  children: PropTypes.node,
};
