import PropTypes from 'prop-types';
import Link from 'next/link';

const SIZE = {
  none: 'p-0',
  xs: 'py-1.5 px-3 text-xs border',
  sm: 'py-2 px-4 text-sm border',
  md: 'py-2 px-5 text-base border',
  lg: 'py-3 px-6 text-lg border-2',
  xl: 'py-4 px-8 text-xl border-2',
};

const TYPE = {
  link: 'link',
  button: 'button',
  submit: 'submit',
};

const VARIANT = {
  primary: 'border-none bg-primary-500 text-white hover:bg-primary-800',
  primaryOutline:
    'border-primary-500 font-semibold text-primary-500 hover:border-primary-800 hover:text-primary-800',
  primaryInvert:
    'border-none font-semibold text-primary-500 bg-white hover:text-white hover:bg-primary-500',
  secondary: 'border-none bg-secondary-500 text-white hover:bg-secondary-800',
  secondaryOutline:
    'border-secondary-500 font-semibold text-secondary-500 hover:border-secondary-800 hover:text-secondary-800',
  secondaryInvert:
    'border-none font-semibold text-secondary-500 bg-white hover:text-white hover:bg-secondary-500',
  tertiary:
    'border-cold-gray-400 text-cold-gray-700 font-semibold hover:border-cold-gray-800 hover:text-cold-gray-800',
  danger:
    'border-danger-500 text-danger-500 font-semibold hover:border-danger-800 hover:text-danger-800',
  pricing:
    'after:ease-[cubic-bezier(0.19, 1, 0.22, 1))] before:ease-[cubic-bezier(0.19, 1, 0.22, 1))] relative overflow-hidden bg-midnight-horizontal before:absolute before:-top-12 before:h-40 before:w-12 before:-translate-x-2/4 before:rotate-[35deg] before:transform before:bg-white before:opacity-0 before:duration-[800ms] after:absolute after:-top-12 after:h-40 after:w-12 after:-translate-x-full after:rotate-[35deg] after:transform after:bg-white after:opacity-0 after:duration-[800ms] hover:before:translate-x-[170%] hover:before:opacity-50 hover:after:translate-x-[200%] hover:after:opacity-50 text-white border-none',
  midnightVerticalA: 'border-none bg-midnight-vertical-a',
  midnightVerticalB: 'border-none bg-midnight-vertical-b',
  text: 'border-none text-gray-700 hover:text-gray-900',
  textWhite: 'border-none text-white h-full hover:text-gray-400',
};

function Icon({path}) {
  if (!path) return null;
  const url = `url('${path}')`;
  return (
    <span
      className={`w-4 flex-shrink-0 bg-[${url}] bg-contain bg-center bg-no-repeat transition-all group-hover:scale-110`}
    >
      &nbsp;
    </span>
  );
}

export default function Button({
  children,
  size,
  type,
  variant,
  href,
  onClick,
  className,
  iconLeft,
  iconRight,
}) {
  // CONSTRUCT STYLE
  const style = `flex group items-center justify-center rounded-md capitalize transition-all whitespace-nowrap ${SIZE[size]} ${VARIANT[variant]} ${className}`;

  // CONSTRUCT CONTENT
  const content = (
    <>
      {iconLeft && <Icon path={iconLeft} />}
      {children}
      {iconRight && <Icon path={iconRight} />}
    </>
  );

  // RENDER NAVIGATION LINK
  if (TYPE[type] === 'link') {
    return (
      <Link href={href} passHref>
        <a href="replace" onClick={onClick} className={style}>
          {content}
        </a>
      </Link>
    );
  }

  // OR RENDER SIMPLE BUTTON
  return (
    <button
      className={style}
      type={TYPE[type] === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
    >
      {content}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  onClick: e => e,
  href: '#',
  className: '',
  size: 'md',
  variant: 'primary',
  children: null,
  iconLeft: null,
  iconRight: null,
};
Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'primaryOutline',
    'primaryInvert',
    'secondary',
    'secondaryOutline',
    'secondaryInvert',
    'tertiary',
    'danger',
    'pricing',
    'midnightVerticalA',
    'midnightVerticalB',
    'text',
    'textWhite',
  ]),
  type: PropTypes.oneOf(['link', 'button', 'submit']),
  size: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl']),
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
};

Icon.defaultProps = {
  path: null,
};
Icon.propTypes = {
  path: PropTypes.string,
};
