import React from 'react';
import PropTypes from 'prop-types';

export default function CategoryBadge({badge}) {
  if (!badge) {
    return null;
  }

  const {asset, variant} = badge;

  switch (variant) {
    case 'menu-badge':
      if (badge.is_animated) {
        return (
          <span>
            <object
              className="pointer-events-none -z-[1] ml-5 h-8 w-6"
              title={`Link Badge ${variant}`}
              data={asset}
              type="image/svg+xml"
            />
          </span>
        );
      }

      return (
        <span>
          <img
            className="pointer-events-none -z-[1] ml-5 h-8 w-6"
            alt={`Link Badge ${variant}`}
            src={asset}
          />
        </span>
      );
    case 'pill':
      return (
        <div className="ml-4 rounded-2xl bg-sunset-horizontal px-2 text-[0.625rem] text-sm">
          {asset}
        </div>
      );
    default:
      return null;
  }
}

CategoryBadge.defaultProps = {
  badge: null,
};

CategoryBadge.propTypes = {
  badge: PropTypes.shape({
    asset: PropTypes.string,
    variant: PropTypes.string,
    is_animated: PropTypes.bool,
  }),
};
