import React from 'react';
import PropTypes from 'prop-types';

import BaseButton from '../../../UI/Molecules/BaseButton';
import {IcCloseXl} from '../../../UI/Atoms/Icons';

export default function ResetButton({className, resetClickHandler}) {
  return (
    <BaseButton
      tabIndex={0}
      className={className}
      variant="textGray"
      onClick={resetClickHandler}
      leftIcon={<IcCloseXl className="absolute right-0 top-0 h-5 w-5 scale-50 fill-white" />}
    />
  );
}

ResetButton.defaultProps = {
  className: '',
};
ResetButton.propTypes = {
  className: PropTypes.string,
  resetClickHandler: PropTypes.func.isRequired,
};
