import React from 'react';
import {Trans} from '@lingui/macro';

export default function MyDownloads() {
  return (
    <>
      <p className="text-lg text-gray-400">&darr;</p>
      <a href="/purchases" className="p-2 text-secondary-500">
        <Trans>Click here to see your downloads</Trans>
      </a>
    </>
  );
}
