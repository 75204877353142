import React from 'react';
import Filter from './Filter';
import Form from './Form';

export default function SearchBar() {
  return (
    <div className="relative flex px-4 pb-2.5 pt-1 lg:px-6 lg:pb-4">
      <Filter className="hidden lg:flex" />
      <Form />
    </div>
  );
}

export const SearchBarPlasmicData = {
  name: 'Nav.SearchBar',
  props: {},
  defaultStyles: {
    position: 'relative',
    display: 'flex',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '1rem',
    flexWrap: 'nowrap',
  },
  importPath: './components/NavBar/SearchBar',
  isDefaultExport: true,
};
