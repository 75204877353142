import {createContext} from 'react';

const NavContext = createContext({
  menuList: [],
  activeFilter: '',
  inputSearch: '',
  isBannerVisible: true,
  isMenuPanelOpen: false,
  isAccountPanelOpen: false,
  userOptions: [],
  showLoginPanel: false,
  menuBarChildren: [],
});

export default NavContext;
