import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ThreeDotMenuItem from './ThreeDotMenuItem';

export default function ThreeDotDropDown({menuItems, itemsShown}) {
  const [menuStyle, setMenuStyle] = useState('rounded-xl');

  return (
    <div className="absolute -z-10 flex pt-1 transition-all">
      <ul
        className={`flex min-w-[160px] cursor-pointer list-none flex-col bg-gray-800 p-0 text-sm ${menuStyle}`}
        data-qa="threedot-dropdown"
        onMouseEnter={() => setMenuStyle('rounded-l-xl')}
      >
        {menuItems.map(({id, label, ...otherProps}) => {
          // used to avoid rendering items already displayed
          if (id < itemsShown + 1) return null;
          return <ThreeDotMenuItem key={id} label={label} {...otherProps} />;
        })}
      </ul>
    </div>
  );
}

ThreeDotDropDown.propTypes = {
  itemsShown: PropTypes.number,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      subCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
};

ThreeDotDropDown.defaultProps = {
  itemsShown: 0,
  menuItems: [],
};
