import React from 'react';
import {
  IcArrowDown as IconMyDownloads,
  IcUser01 as AvatarIcon,
  IcForYou as ForYouIcon,
  IcMyPlaceit as MyPlaceitIcon,
} from '../../../../UI/Atoms/Icons';
import AccountDropdown from '../AccountDropdown';
import MyDownloads from '../MyDownloads';
import UserOptions from '../UserOptions';
import UserMenuItem from './UserMenuItem';

export default function UserMenu() {
  return (
    <div className="flex h-full items-center capitalize text-white">
      {/* MY DOWNLOADS */}
      <AccountDropdown
        Component={MyDownloads}
        className="absolute -right-32 top-11 flex h-32 w-96 flex-col items-center justify-center bg-white shadow-lg"
      >
        <div className="mx-8 flex h-11 w-5 items-center">
          <IconMyDownloads className="h-5 w-5 fill-white group-hover:invert-[53%]" />
        </div>
      </AccountDropdown>

      <UserMenuItem label="for you" href="/for-you">
        <ForYouIcon className="h-5 w-5" />
      </UserMenuItem>

      <UserMenuItem className="mx-6" label="my placeit" href="/my_placeit">
        <MyPlaceitIcon className="h-5 w-5" />
      </UserMenuItem>

      {/* USER MENU */}
      <AccountDropdown
        Component={UserOptions}
        className="absolute right-0 top-11  h-max w-60 bg-white shadow-md"
      >
        <UserMenuItem label="my account" href="/account">
          <AvatarIcon className="h-5 w-5" />
        </UserMenuItem>
      </AccountDropdown>
    </div>
  );
}
