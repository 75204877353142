import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {MAINMENU_CLICKED, EMAIL_MAINMENU_CLICKED, trackEvent} from '../../../../../lib/analytics';
import RightPointer from '../../../../../public/static/right-pointer.svg';
import CategoryDropdown from '../CategoryDropdown';

export default function ThreeDotMenuItem({label, path, ...otherProps}) {
  const [isOpen, setIsOpen] = useState(false);

  // send a segment event after navigating out to the category path.
  const handleClick = () => {
    trackEvent(MAINMENU_CLICKED, {label: path});
    trackEvent(EMAIL_MAINMENU_CLICKED, {label: path});
  };

  return (
    <li
      className="group flex h-[3.25rem] items-center justify-between whitespace-nowrap first:rounded-tl-xl last:rounded-bl-xl hover:bg-gray-700"
      onMouseLeave={() => setIsOpen(false)}
    >
      <a
        href={path}
        className="flex h-full w-full items-center justify-between px-7"
        onMouseEnter={() => setIsOpen(true)}
        onClick={handleClick}
      >
        {label}
        <RightPointer />
      </a>

      {isOpen && <CategoryDropdown item={otherProps} threeDotMenu />}
    </li>
  );
}

ThreeDotMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
