import React, {useContext} from 'react';
import {UserContext} from '../../../../context/UserContext';
import NavContext from '../../data/NavContext';
import VisitorMenu from './VisitorMenu';
import UserMenu from './UserMenu';
import OneTap from '../../../Login/OneTap';

export default function AccountMenu() {
  const {currentUser} = useContext(UserContext);
  const {mockLogin} = useContext(NavContext);

  return (
    <>
      <OneTap user={currentUser} />
      {currentUser?.loggedIn === !mockLogin ? <UserMenu /> : <VisitorMenu />}
    </>
  );
}
