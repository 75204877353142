import React from 'react';
import PropTypes from 'prop-types';
import {IcCrown01} from '../../../../UI/Atoms/Icons';

export default function UserAccountType({accountType}) {
  if (accountType === 'Unlimited') {
    return <Unlimited>{accountType}</Unlimited>;
  }

  return <FreeAccount>{accountType}</FreeAccount>;
}
UserAccountType.propTypes = {
  accountType: PropTypes.string.isRequired,
};

/** **********************
 *  HELPER COMPONENTS
 * ********************* */
function Unlimited({children}) {
  return (
    <div className="flex items-center text-xs font-semibold uppercase text-primary-500">
      <IcCrown01 variant="multi" fill="#E19DFC" stroke="#860EE6" className="mr-1 h-5 w-5" />
      {children}
    </div>
  );
}
Unlimited.propTypes = {
  children: PropTypes.string.isRequired,
};

function FreeAccount({children}) {
  return (
    <div className="flex flex-row text-xs font-semibold uppercase text-gray-600">{children}</div>
  );
}
FreeAccount.defaultProps = {
  children: 'Free Account',
};
FreeAccount.propTypes = {
  children: PropTypes.string,
};
