const ENDPOINT_URL = '/api/v1/stages/get_stages_count_from_term';

export async function getSearchTermCount(query, category) {
  const queryParams = new URLSearchParams();
  queryParams.set('search', query);

  if (category) {
    queryParams.set('category_id', category);
  }

  return fetch(`${ENDPOINT_URL}?${queryParams.toString()}`).then(res => res.json());
}

export default {
  getSearchTermCount,
};
