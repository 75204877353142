import React from 'react';
import PropTypes from 'prop-types';
import {
  IcFavoriteOff as IconFavorite,
  IcUser02 as IconAccount,
  IcFolder as IconMyFolder,
  IcCircleClock as IconEdited,
  IcLogout as IconLogout,
  IcDownload as IconMyDownloads,
  IcMyPlaceit as IconMyPlaceit,
} from '../../../../UI/Atoms/Icons';

export default function UserOptionIcon({name, className}) {
  switch (name) {
    case 'account':
      return <IconAccount className={className} />;
    case 'heart':
      return <IconFavorite className={className} />;
    case 'myFolder':
      return <IconMyFolder className={className} />;
    case 'drafts':
      return <IconEdited className={className} />;
    case 'logout':
      return <IconLogout className={className} />;
    case 'myDownloads':
      return <IconMyDownloads className={className} />;
    case 'myPlaceit':
      return <IconMyPlaceit className={className} />;
    default:
      return null;
  }
}
UserOptionIcon.defaultProps = {
  name: null,
  className: '',
};
UserOptionIcon.propTypes = {
  name: PropTypes.oneOf([
    'account',
    'heart',
    'myFolder',
    'drafts',
    'logout',
    'myDownloads',
    'myPlaceit',
  ]),
  className: PropTypes.string,
};
