import {trackEvent} from './analytics';
import ssoHostname from '../var/sso-hostname';

export default function ssoLogout(user) {
  window.envatoSso.setConfig({host: ssoHostname});

  const onSuccess = () => {
    trackEvent('sso.ssoLoggedOut', {
      error: false,
      type: 'event',
      message: 'Logged out from Envato SSO successful',
      username: user ? user.username : '',
      userEmail: user ? user.email : '',
    });
  };
  const onSignOutError = error => {
    const {context} = error;

    if (error.type === 'ValidationError') {
      console.log(`Failed to logout at SSO: ${context.errorMessage}`);
    } else {
      console.warn(`SSO Error signing out from SSO: ${error.message}`);
    }
  };

  return window.envatoSso.signOut().then(onSuccess).catch(onSignOutError);
}
