import React, {useState, useContext, useEffect} from 'react';
import {useRouter} from 'next/router';
import {datadogLogs} from '@datadog/browser-logs';

import NavContext from '../../data/NavContext';
import Input from './Input';
import SubmitButton from './SubmitButton';
import {IcSearch01 as MagnifyingGlassIcon} from '../../../UI/Atoms/Icons';
import MobileActiveSearch from '../../Mobile/Search/ActiveSearch';
import {reportSubmission} from '../SearchBarEventReporter';

import {SEARCH_TERM_COUNT_ERROR} from '../../../../lib/analytics';

import {getSearchTermCount} from '../../../../lib/placeit/services/search';

const CATEGORY_LABEL = {
  ALL: 'all',
  LOGO_MAKER: 'logo-maker',
  LOGOS: 'logos',
  GAMING: 'gaming-templates',
};

const FILTER_LABELS = {
  all: 'all categories',
  mockups: 'Mockups',
  'design-templates': 'Designs',
  logos: 'Logos',
  videos: 'Videos',
  'gaming-templates': 'Gaming',
};

const ACTIVE_FILTER = {
  'all categories': {
    slug: 'c/all',
  },
  Videos: {
    slug: '/c/videos',
  },
  Mockups: {
    slug: '/c/mockups',
  },
  Logos: {
    slug: '/c/logos',
  },
  Designs: {
    slug: '/c/design-templates',
  },
  Gaming: {
    slug: '/gaming-templates',
  },
};

const CATEGORY_INDEX = 2;
const CATEGORY_GAMING_INDEX = 1;

export default function Form() {
  const {menuList, activeFilter, inputSearch, setInputSearch, setActiveFilter} =
    useContext(NavContext);
  const router = useRouter();
  const [isSearching, setIsSearching] = useState(false);
  const [resultsNumber, setResultsNumber] = useState(null);

  // PULL CATEGORY OBJ
  const categoryFiltered = menuList.find(el => el.label === activeFilter);

  useEffect(() => {
    const {pathname} = router;
    const urlParts = pathname?.split('/');
    const isGamingCategory = urlParts && urlParts[CATEGORY_GAMING_INDEX] === CATEGORY_LABEL.GAMING;
    const isAnyOtherCategory = urlParts && FILTER_LABELS[urlParts[CATEGORY_INDEX]];

    if (isAnyOtherCategory) {
      setActiveFilter(FILTER_LABELS[urlParts[CATEGORY_INDEX]]);
    }

    if (isGamingCategory) {
      setActiveFilter(FILTER_LABELS[urlParts[CATEGORY_GAMING_INDEX]]);
    }
  }, []);

  // SUBMIT HANDLER
  // Search Logic goes here.
  const onSubmit = (event, query) => {
    event.preventDefault();

    const {path} = categoryFiltered;
    let url = new URL(path, window.location.origin);
    let categoryQuery = categoryFiltered.slug;
    if (categoryQuery === CATEGORY_LABEL.ALL) {
      categoryQuery = null;
    }
    if (CATEGORY_LABEL.LOGO_MAKER === categoryQuery) {
      categoryQuery = CATEGORY_LABEL.LOGOS;
    }

    setActiveFilter(activeFilter);

    if (!query) return;

    reportSubmission();
    setResultsNumber(null);

    getSearchTermCount(query, categoryQuery)
      .then(count => {
        if (count === 0) {
          setResultsNumber(0);
        }

        setResultsNumber(count);

        if (ACTIVE_FILTER[activeFilter]) {
          url = new URL(ACTIVE_FILTER[activeFilter].slug, window.location.origin);
        }

        url.searchParams.set('search', query);
        router.push(url);
      })
      .catch(error => {
        datadogLogs.logger.error(SEARCH_TERM_COUNT_ERROR, error.toString());
      });
  };

  const handleChange = newValue => {
    if (typeof newValue === 'string') {
      setInputSearch(newValue);
    } else if (newValue && newValue.target && typeof newValue.target.value === 'string') {
      setInputSearch(newValue.target.value);
    }
  };

  // RENDER
  return (
    <form onSubmit={e => onSubmit(e, inputSearch)} className="relative flex w-full justify-center">
      <Input
        placeholder={categoryFiltered?.placeholder}
        onChange={handleChange}
        value={inputSearch}
        setInputSearch={setInputSearch}
        onClick={() => setIsSearching(true)}
        onSubmit={onSubmit}
        resultsNumber={resultsNumber}
        isSearchingState={isSearching}
      />
      <MagnifyingGlassIcon className="absolute left-0 top-1.5 z-20 mx-2 h-5 w-5 bg-contain text-cold-gray-800 transition-all peer-focus:left-0 peer-focus:opacity-0 lg:hidden" />
      <SubmitButton className="hidden lg:m-0 lg:flex lg:h-full lg:w-11 lg:rounded-l-none" />

      {/* MOBILE ACTIVATE SEARCH */}
      {isSearching && <MobileActiveSearch isActive={isSearching} setActivate={setIsSearching} />}
    </form>
  );
}
