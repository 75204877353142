import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../../data/NavContext';
import FilterButton from './FilterButton';
import FilterDropdown from './FilterDropdown';
import FadeBackground from '../../Mobile/Panel/FadeBackground';

export default function Filter({className}) {
  const {activeFilter, setActiveFilter, menuList} = useContext(NavContext);
  const [isOpen, setIsOpen] = useState(false);

  const onSetFilter = event => setActiveFilter(event.target.textContent, setIsOpen(false));

  return (
    // CONTAINER
    <div className={`${className} relative`}>
      <FilterButton
        onClick={() => setIsOpen(prevState => !prevState)}
        isOpen={isOpen}
        filter={activeFilter}
      />
      <FilterDropdown
        isOpen={isOpen}
        filter={activeFilter}
        onSetFilter={onSetFilter}
        content={menuList}
      />
      <FadeBackground
        backgroundColor="bg-transparent block"
        onClick={() => setIsOpen(false)}
        isActive={isOpen}
      />
    </div>
  );
}

Filter.defaultProps = {
  className: '',
};
Filter.propTypes = {
  className: PropTypes.string,
};
