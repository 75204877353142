import React, {useState, useContext, useEffect} from 'react';
import CategorySubMenu from './CategorySubMenu';
import NavContext from '../../data/NavContext';
import wait from '../../../../lib/utils/wait';

export default function CategoryMenu() {
  const {menuList} = useContext(NavContext);
  const [categorySelected, setCategorySelected] = useState(null);
  const [animateIn, setAnimateIn] = useState(true);
  const [animateCategory, setAnimateCategory] = useState('');
  const [animateSubMenu, setAnimateSubMenu] = useState('');

  const clickCategory = ({target: {textContent}}) => {
    const [category] = menuList.filter(el => el.label === textContent);
    setCategorySelected(category);
    setAnimateIn(true);
  };

  const animationStyle = {
    fadeInCategory: 'animate-fadeInCategory',
    fadeOutCategory: 'animate-fadeOutCategory',
    fadeInSubMenu: 'animate-fadeInSubMenu',
    fadeOutSubMenu: 'animate-fadeOutSubMenu',
  };

  // animate mount and unmount of Menus
  useEffect(() => {
    if (animateIn) {
      setAnimateCategory(animationStyle.fadeInCategory);
      setAnimateSubMenu(animationStyle.fadeInSubMenu);
    } else {
      setAnimateCategory(animationStyle.fadeOutCategory);
      setAnimateSubMenu(animationStyle.fadeOutSubMenu);
    }
  }, [animateIn]);

  const handleExit = async () => {
    setAnimateIn(false);

    // gives time for the animation to run before umount
    await wait(100, () => setCategorySelected(null));
  };

  return (
    <>
      <div className="flex-col border-t border-gray-100">
        {menuList.map(({id, label}) => {
          if (!id) return null;
          return (
            <button
              key={id}
              className={`flex w-full items-center justify-between px-7 py-3 text-lg capitalize text-gray-800 ${animateCategory}`}
              type="button"
              onClick={clickCategory}
              aria-label={`item-${label}`}
              name={label}
            >
              <span className="flex-grow text-left">{label}</span>
              <span className="pointer-events-none inline-block h-3 w-2 bg-[url('/static/right-pointer.svg')] bg-contain bg-center bg-no-repeat invert saturate-0 sepia-0" />
            </button>
          );
        })}
      </div>
      {categorySelected && (
        <CategorySubMenu
          option={categorySelected}
          reset={handleExit}
          animateCategory={animateCategory}
          animateSubMenu={animateSubMenu}
        />
      )}
    </>
  );
}
