import React from 'react';
import PropTypes from 'prop-types';
import UserOptionIcon from './UserOptionIcon';

export default function UserOption({children, name, href, icon, onClick, isLogoutItem}) {
  if (isLogoutItem) {
    return (
      <button
        type="button"
        className="flex w-full items-center border-t-0 py-4 pl-6 text-lg hover:bg-gray-100 lg:border-t lg:border-gray-100 lg:text-base"
        onClick={onClick}
        aria-label="user-option-logout"
        name={name}
      >
        <UserOptionIcon className="h-5 w-5 text-gray-800" name="logout" />
        <span className="ml-2 text-lg font-semibold text-gray-800 lg:text-base lg:font-normal">
          Log out
        </span>
      </button>
    );
  }

  return (
    <a
      href={href}
      className={`flex w-full items-center py-4 pl-6 text-lg text-gray-800 hover:bg-gray-100 lg:text-base ${
        children === 'My Account' ? 'border-b-0 lg:border-b lg:border-gray-100' : ''
      }`}
      onClick={onClick}
      aria-label={`user-option-${children}`}
      name={name}
    >
      <UserOptionIcon name={icon} className="h-5 w-5 text-gray-800" />
      <span className="ml-2 text-lg font-semibold text-gray-800 lg:text-base lg:font-normal">
        {children}
      </span>
    </a>
  );
}

UserOption.defaultProps = {
  onClick: () => null,
  href: '#',
  icon: '',
  isLogoutItem: false,
  children: '',
  name: '',
};
UserOption.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isLogoutItem: PropTypes.bool,
  name: PropTypes.string,
};
