import React from 'react';
import PropTypes from 'prop-types';

export default function FilterDropdown({isOpen, content, onSetFilter, filter}) {
  if (isOpen) {
    return (
      // DROPDOWN CONTAINER
      <div className="fixed left-0 top-[96px] z-30 h-full w-full animate-dropDown bg-white py-3 lg:absolute lg:top-11 lg:h-auto lg:rounded-md lg:shadow-md">
        {content.map(({label, id, isDisplayedInFilter}) => {
          if (isDisplayedInFilter) {
            return (
              // LIST OF OPTIONS
              <div key={id}>
                <button
                  type="button"
                  aria-label={`filter-item-${label}`}
                  className={`hover-bg-gray-100 flex w-full items-baseline border-b border-gray-100 px-5 py-4 text-left lg:border-none lg:px-5 lg:py-2.5 lg:text-sm lg:hover:bg-gray-100 ${
                    filter === label ? 'text-info-500' : 'text-inherit'
                  }`}
                  onClick={onSetFilter}
                >
                  {/* TEXT */}
                  <span className="capitalize">{label}</span>
                  {/* CHECK ICON */}
                  {filter === label && (
                    <span className="pointer-events-none ml-5 h-2.5 w-2.5 bg-[url('/static/check-lg.svg')] bg-contain bg-center bg-no-repeat" />
                  )}
                </button>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
}

FilterDropdown.propTypes = {
  filter: PropTypes.string.isRequired,
  onSetFilter: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
};
