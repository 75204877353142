import React, {useContext} from 'react';
import classNames from 'classnames/bind';
import {Trans} from '@lingui/macro';
import {UserContext} from '../../../../context/UserContext';
import BaseButton from '../../../UI/Molecules/BaseButton';

export default function VisitorMenu() {
  const {ssoModalOpener, currentUser} = useContext(UserContext);
  return (
    <div
      className="ml-8 flex capitalize"
      style={{
        transition: 'opacity 0.25s ease-in',
        opacity: currentUser?.loggedIn === 'loading' ? '0.25' : '1',
        background: currentUser?.loggedIn === 'loading' ? 'url(/assets/loader-easing.gif)' : '',
      }}
    >
      <BaseButton
        type="button"
        variant="textWhite"
        className={classNames('pr-4 last:pr-0', {
          invisible: currentUser?.loggedIn === 'loading',
        })}
        onClick={() => ssoModalOpener(true)}
      >
        <Trans>Free Account</Trans>
      </BaseButton>

      <BaseButton
        type="button"
        variant="textWhite"
        className={classNames('px-4 last:pr-0', {
          invisible: currentUser?.loggedIn === 'loading',
        })}
        onClick={() => ssoModalOpener()}
      >
        <Trans>Log In</Trans>
      </BaseButton>
    </div>
  );
}
